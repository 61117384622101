import { ANALYSIS_DOMAIN, RES_DOMAIN, STATIC_DOMAIN } from "constant/config";
import { AD_ON_OFF_INFO } from "../actions/adBanner.action";

const loadScript = require("load-script");


/**
 * helpers.js 파일내 라인이 너무 길어져 분리시켜 load-script 로직 한 곳으로 모아 관리하는 곳
 * script를 불러와야 할 경우 이곳에 함수 추가 및 scriptLoad사용해 주시길 바랍니다.
 */

 /**
  * loadScript 사용
  * @param {*} url 가져올 파일 url 
  * @param {*} callback 파일내 로직 제어할 callback 함수 없을 경우 null 혹은 parameter x
  * @param {*} attrId script 추가 생성 방지 id 
  */
export const scriptLoad = (url, callback, tagId) => {
    if(tagId) {
        let option = {attrs: {id : `${tagId}`}};
        let idCheck = document.getElementById(tagId);
        
        if(!idCheck) {
            loadScript(url, option, callback);
        } 
    } else {
        loadScript(url, callback);
    }
}

const checkExistingScript = (url) => {
  return Array.from(document.querySelectorAll('script')).find((script) => {
    const urlWithoutQuery = script.src.split('?')[0];
    return urlWithoutQuery === url;
  });
};

export const loadScriptAsync = (url, option) => {
  return new Promise((resolve, reject) => {
    // 이미 스크립트가 로드되어 있는지 확인
    const originalUrl = url.split('?')[0];
    const existingScript = checkExistingScript(originalUrl); // document.querySelector(`script[src="${url}"]`);

    if (existingScript) {
      // 기존 스크립트가 있으면 제거
      existingScript.parentElement.removeChild(existingScript);
    }

    return loadScript(url, option, (error, script) => {
      if (error) {
        reject(error);
      } else {
        resolve(script);
      }
    });
  });
};

/**
 * WEB UV 로그 호출
 */
export const callAU = (action, referrer) => {
    console.log("callAU");
    window.szLogAction = action;
    scriptLoad(`${ANALYSIS_DOMAIN}/_au.js`, null, 'webuv');

    setTimeout(() => {
        if(!action) {
            window.referrer = referrer;
            window._au();
        }
        window.szLogAction = "";
    }, 35);// 첫 로드 이후 js 파일을 완전 로드후 사용하기 위해 time out 35ms 추가
};

/**
 * 카카오톡 공유하기 기능
 */
export const kakaoMessage = ( kakaoInfo, active = false ) => {
    if(active) {
        handleOpenKakao(kakaoInfo);
        return;
    }
    scriptLoad(`${RES_DOMAIN}/script/kakao.min.js`, null, 'kakao');
}

/**
 * 채널 광고 on/off 컨트롤
 */
export const adBannerControl = () => async (dispatch) => { 
    scriptLoad(`${STATIC_DOMAIN}/asset/service/ad/check_new_ad.js`, () => {
        const payloadData = {
            bannerOnOff : (window.bAdPcStationBanner !== undefined && window.bAdPcStationBanner) ? true : false,
            mainBannerOnOff : (window.bAdPcBjMainBanner !== undefined && window.bAdPcBjMainBanner) ? true : false,
            channelBannerOnOff: (window.bAdPcChannelListBanner && window.bAdPcChannelListBanner === 1) ? true : false
        };
        dispatch({
            type: AD_ON_OFF_INFO,
            payload: payloadData
        });
    }, 'checkAd');
};

/**
 * 이모티콘 static 공용화
 */
export const staticEmoticon = () => {
    scriptLoad(`${STATIC_DOMAIN}/asset/service/common/emoticon.js`, null, 'emoticonCommon');

    if (!window.emoticonCommon || !window.emoticonCommon.emoticonList) {
        setTimeout(() => {}, 100); // 첫 로드 이후 js 파일을 완전 로드후 사용하기 위해 time out 100ms 추가
    }
}

const handleOpenKakao = (kakaoInfo) => {
    /**
     * secret key 초기화 및 설정
     * - 초기화 안되었을 경우 초기화 진행
     */
    if (window.kakao && !window.Kakao.isInitialized()) {
        window.Kakao.init('b4b3dd0cdd9894c56957b935d868c7ea');
    }
    /**
     * 초기화 및 설정이 되었을 경우에만 발송
     */
    if (window.kakao && window.Kakao.isInitialized()) {
        const { templateId, kakaoArgs = {} } = kakaoInfo;

        /**
         * 카카오톡 모바일 공유 테스트시 user agent가 임의로 변경된 환경 크롬 개발자 모드에서는 동작 안됨
         * 모바일로 테스트 진행 필수
         * 
         * - 디바이스 탐, 패드, 갤탭 경우 앱이 아닌 웹 새창으로 이동됨
         */
        window.Kakao.API.cleanup();    //카카오톡 공유와 관련된 리소스 해제 후 sendCustom 실행
        window.Kakao.Link.sendCustom({
            templateId: templateId,
            templateArgs: kakaoArgs,
            installTalk: true  //카카오톡 미설치시 설치화면으로
        });
    }
}