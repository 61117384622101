import { reducer as toastrReducer } from "components/toastr";
import adReducer from "reducers/ad.reducer";
import afreecaSearchReducer from "reducers/afreeca.search.reducer";
import authenticationReducer from "reducers/authentication.reducer";
import bannerReducer from "reducers/banner.reducer";
import ckeditorReducer from "reducers/ckeditor.reducer";
import commentImageReducer from "reducers/comment.image.reducer";
import commentsReducer from "reducers/comments.reducer";
import emoticonOgqReducer from "reducers/emoticon.ogq.reducer";
import emoticonRecentReducer from "reducers/emoticon.recent.reducer";
import emoticonSignatureReducer from "reducers/emoticon.signature.reducer";
import guestReducer from "reducers/guest.reducer";
import homeReducer from "reducers/home.reducer";
import pageReducer from "reducers/page.reducer";
import playlistLayerReducer from "reducers/playlist.layer.reducer";
import postListReducer from "reducers/post.list.reducer";
import postBottomListReducer from "reducers/post.bottom.list.reducer";
import postNearbyReducer from "reducers/post.nearby.reducer";
import { postsPhotoDialogReducer, postsPhotoReducer } from "reducers/post.photo.reducer";
import postReducer from "reducers/post.reducer";
import postWriteReducer from "reducers/post.write.reducer";
import postsReducer from "reducers/posts.reducer";
import shopfreecaListReducer from "reducers/shopfreeca.list.reducer";
import shopfreecaLiveReducer from "reducers/shopfreeca.live.reducer";
import reportReducer from "reducers/report.reducer";
import searchBjReducer from "reducers/search.bj.reducer";
import searchReducer from "reducers/search.reducer";
import settingBannerReducer from "reducers/setting.banner.reducer";
import settingBanWordReducer from "reducers/setting.banword.reducer";
import settingChannelArtReducer from "reducers/setting.channelart.reducer";
import settingMenuReducer from "reducers/setting.menu.reducer";
import settingReducer from "reducers/setting.reducer";
import clipFilterReducer from "reducers/clip.filter.reducer";
import pinLayerReducer from "reducers/pin.layer.reducer";
import homeIctMenuReducer from "./home.ict-menu.reducer";
// setting
import settingVodAuthListReducer from "reducers/setting.vodauth.list.reducer";
import settingVodRejectListReducer from "reducers/setting.vodreject.list.reducer";
import settingUserClipBlackListReducer from "reducers/setting.userclip.blacklist.reducer";
import stationReducer from "reducers/station.reducer";
import useCookieReducer from "reducers/use.cookie.reducer";
import settingShopfreecaReducer from "reducers/setting/setting.shopfreeca.reducer";
import settingPlaylistReducer from "reducers/setting/setting.playlist.reducer";
// hooks
import useStorageItemReducer from "reducers/use.storage.item.reducer";
import vodsReducer from "reducers/vods.reducer";
import giftEventReducer from "reducers/gift.event.reducer";
import adBannerReducer from "reducers/adBanner.reducer";
import tagBjSearchReducer from "reducers/tag.bj.saerch.reducer";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";



const reducers = combineReducers({
    form: formReducer,
    authenticationReducer,
    postReducer,
    postListReducer,
    postBottomListReducer,
    postsReducer,
    postWriteReducer,
    postNearbyReducer,
    postsPhotoReducer,
    postsPhotoDialogReducer,
    shopfreecaListReducer,
    shopfreecaLiveReducer,
    stationReducer,
    commentsReducer,
    commentImageReducer,
    vodsReducer,
    afreecaSearchReducer,
    searchReducer,
    reportReducer,
    pageReducer,
    ckeditorReducer,
    homeReducer,
    guestReducer,
    bannerReducer,
    playlistLayerReducer,
    emoticonSignatureReducer,
    emoticonOgqReducer,
    emoticonRecentReducer,
    clipFilterReducer,
    pinLayerReducer,
    homeIctMenuReducer,

    settingReducer,
    searchBjReducer,
    settingChannelArtReducer,
    settingMenuReducer,
    settingShopfreecaReducer,
    settingPlaylistReducer,
    settingVodAuthListReducer,
    settingVodRejectListReducer,
    settingBanWordReducer,
    settingBannerReducer,
    settingUserClipBlackListReducer,

    useStorageItemReducer,
    useCookieReducer,
    adReducer,
    adBannerReducer,

    giftEventReducer,
    tagBjSearchReducer,

    toastr: toastrReducer, // <- Mounted at toastr.
});

export default reducers;
