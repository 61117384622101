import { homeDispatch, HOME_WILL_UNMOUNT } from "actions/home.action";
import { MAIN_CLASS, wrapClassName } from "actions/page.action";
import { fetcBjInfo, fetchNotice, fetchStationInfo } from "actions/station.action";
import Footer from "components/footer/Footer";
import Leftpane from "components/left/Leftpane";
import LoadingMobile from "components/loading/LoadingMobile";
import RestoredScroll from "components/RestoredScroll";
import HelmetTitle from "components/HelmetTitle";
import { STATION_DOMAIN, APP_SCHEME } from "constant/config";
import { MAIN_STATION } from "constant/helmet.title";
import { getNickname, isWebview, setSessionItem, getSessionItem } from "helpers";
import FloatingBanner from "pages/station/banner/FloatingBanner";
import { StationError } from "pages/station/error/StationError";
import { StationErrorMobile } from "pages/station/error/StationErrorMobile";
import ContentsHeader from "pages/station/home/ContentsHeader";
import NoticePopup from "pages/station/notice/NoticePopup";
import querystring from "querystring";
import React, { Component, Suspense } from "react";
import cookie from "react-cookies";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { SubPageMobile } from "./SubPageMobile";
import { callAU, kakaoMessage } from "libs/scriptLoad";
import AdComponent from "components/AdComponent";

const Guest = React.lazy(() => import("pages/station/guest/Guest"));
const Home = React.lazy(() => import("pages/station/home/Home"));
const MainMobile = React.lazy(() => import("pages/station/main/MainMobile"));
const Post = React.lazy(() => import("pages/station/post/Post"));
const Posts = React.lazy(() => import("pages/station/posts/Posts"));
const Report = React.lazy(() => import("pages/station/report/Report"));
const SearchMobile = React.lazy(() => import("pages/station/search/mobile/SearchMobile"));
const Search = React.lazy(() => import("pages/station/search/Search"));
const Vods = React.lazy(() => import("pages/station/vods/Vods"));
const Write = React.lazy(() => import("pages/station/write/Write"));
const WriteMobile = React.lazy(() => import("pages/station/write/WriteMobile"));
const Shopfreeca = React.lazy(() => import("pages/station/posts/Shopfreeca"));
class Station extends Component {
    constructor(props) {
        super(props);
        const { match, location } = this.props;
        global.bj_id = match.params.id.toLowerCase();
        this.props.fetchStationInfo(global.bj_id);
        this.props.fetcBjInfo();
        const params = querystring.parse(location.search.substr(1));
        setSessionItem("szAppLocation", params.szAppLocation || "");
        setSessionItem("nGiftNo", params.nGiftNo || "");
        this.oldLocation = null;
        this.isLocationChange = false;
        this.contentHeaderRef = React.createRef();
        this.state = {
            contentStyle: {},
        };
    }

    componentWillMount() {
        //페이지이동할때 이벤트를 받음
        const { history } = this.props;
        this.location = this.props.location;
        this.unsubscribeFromHistory = history.listen(() => {
            const prev_referer = this.props.location.pathname
            this.handleLocationChange(history.location, prev_referer);

        });

        this.props.homeDispatch(HOME_WILL_UNMOUNT); //처음로딩시 홈이아님
        let mainClass = this.props.location.pathname === `/${global.bj_id}` ? MAIN_CLASS : '';
        mainClass = (isMobile && (this.props.location.pathname === `/${global.bj_id}/post/write` || this.props.location.pathname === `/${global.bj_id}/search`)) ? MAIN_CLASS : mainClass;
        this.props.wrapClassName([mainClass, "renew241121_common"]); //클래스 초기화


        const theme = cookie.load("theme");
        if (theme === "dark" && document.documentElement.lang === "ko") {
            const html = document.getElementsByTagName('html')[0];
            if (html) {
                html.setAttribute('dark', 'true');
            }
        }

        // 페이지 로드 or 페이지 이동될때 카카오톡 script load 시킴 
        kakaoMessage(null);
    }

    componentWillUnmount() {
        if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            window.location.reload();
        }

        /**
         * 정보창을 업데이트함
         */
        if (this.props.pageReducer.bsWrapClassName !== nextProps.pageReducer.bsWrapClassName) {
            this.handleDidUpdate();
        }
    }

    /**
     * 페이지 이동시 하위페이지에서 ContentsHeader를 축소시킴
     * @param {*} location
     */
    handleLocationChange = (location, prev_referer) => {
        /**
         * 페이지 이동시킬때만
         */
        if (this.location.pathname === `/${global.bj_id}` && location.pathname === `/${global.bj_id}`){
            // this.props.homeDispatch(HOME_WILL_UNMOUNT); //처음로딩시 홈이아님
            // 둘다 홈일 때 메인 클래스로 초기화
            this.props.wrapClassName([MAIN_CLASS, "renew241121_common", "renew241121"]); //클래스 초기화
        } else {
            // 다시 홈으로 돌아오는 케이스
            let mainClass = '';

            mainClass = location.pathname === `/${global.bj_id}` ?  MAIN_CLASS : '';
            if (isMobile) {
                mainClass = location.pathname === `/${global.bj_id}/search` ? MAIN_CLASS : '';
            }

            let renewClass = location.pathname === `/${global.bj_id}` ?  'renew241121' : '';
            this.props.wrapClassName([ mainClass , "renew241121_common", renewClass]); //클래스 초기화
        }

        if (isWebview) {
            //웹뷰에게 페이지 이동을 알림
            window.location = `${APP_SCHEME}browser/loaded`;
        }

        /**
         * UV한번만 호출
         */
        if (this.oldLocation !== null) {
            //외부에서 채널 진입 시 szReferer 이 document.referrer로 찍혀야 됨
            //채널 내에서 pathname 이 바뀔 때만 szReferer = window.referrer 탈 수 있도록 수정
            let referrer = STATION_DOMAIN + prev_referer;
            callAU("", referrer);
            
            this.isLocationChange = true;
        }

        /**
         * 이동 경로 localStorage에 저장 - 2022-07-07 부터 vod에서 시작 게시글은 적용 x
         */
        const { pathname, search = '' } = location;
        const boardType = pathname.split('/')[2];
        const pageType = pathname.split('/')[3] || 'all';
        
        // vod 게시판 일때만
        if(boardType === 'vods' && search) { 
            let regex = new RegExp(/[&|\?]orderBy=([\w]+)&?/, 'g');
            let regexList = regex.exec(search);

            if(regexList) {
                let orderBy = regexList[1];
                let keyValue = `{"${pageType}" : "${orderBy}"}`;
                let result = getSessionItem('keep_order') || '';
    
                if (!result) {
                    setSessionItem('keep_order', [keyValue]);
                } else {
                    let check = false;
                    result = result.map((order) => {
                        let typeOrder = JSON.parse(order);
                        if(Object.keys(typeOrder)[0] === pageType) {
                            check = true;
                            return `{"${pageType}" : "${orderBy}"}`
                        } 
    
                        // 기존에 있던건 지우고 
                        return order;
                    });
    
                    if(!check) {
                        result.push(keyValue);
                    }
    
                    setSessionItem('keep_order', result);
                }            
            }
        }

        this.oldLocation = location;

        // /**
        //  * location.state.scrollTop 값이 true일때만 스크롤함
        //  */
        // if (!isMobile && (!location.state || !location.state.disableScroll)) {
        // 	documentScrollTop();
        // }
    };
    /**
     * 프로필메시지 확장
     */
    handleDidUpdate = () => {
        setTimeout(() => {
            if (this.contentHeaderRef.current) {
                this.setState({
                    contentStyle: { marginTop: this.contentHeaderRef.current.getElementsByClassName("bs-infomation")[0].offsetHeight - 1 },
                });
            }
        }, 10);
    };

    render() {
        const { contentStyle } = this.state;
        const { match, stationInfo, location } = this.props;
        const { data = {}, loading, error } = stationInfo;
        const { station = {} } = data;
        const description = station.display ? station.display.profile_text : "";
        const title = this.props.t("{{user_nick}}의 채널", { user_nick: getNickname() });
        const keywords = `${getNickname()} 팬카페, ${getNickname()} 채널, 숲, SOOP, 스트리머, 인터넷 방송, 인터넷 방송 커뮤니티, 개인방송`;
        const canonicalPath = `${STATION_DOMAIN}/${global.bj_id}`;
        const mainRightBnr = `/${global.bj_id}` === location.pathname ? <AdComponent code="x50"/> : ""; //채널 홈에만 광고 노출

        if (isMobile) {
            if (loading) {
                return <LoadingMobile />;
            }
            if (error) {
                return <StationErrorMobile error={error} />;
            }
            const { location } = this.props;
            return (
                <React.Fragment>
                    <HelmetTitle title = {title} description = {description} keyword = {keywords} canonicalPath = {canonicalPath} type = {MAIN_STATION} />
                    <RestoredScroll>
                        <Suspense fallback={<LoadingMobile />}>
                            <Switch>
                                {/* 글수정 */}
                                <Route key="edit-client" path={`${match.path}/post/:title_no(\\d+)/:method`} component={WriteMobile} />
                                {/* 글보기 */}
                                <Route
                                    path={`${match.path}/post/:title_no(\\d+)`}
                                    render={() => (
                                        <SubPageMobile>
                                            <Post />
                                        </SubPageMobile>
                                    )}
                                />
                                {/* 글쓰기 */}
                                <Route key="write-client" path={`${match.path}/post/:method`} component={WriteMobile} />
                                {/* 검색 */}
                                <Route key="mobile-search" path={`${match.path}/search`} component={SearchMobile} />
                                {/* 리포트 */}
                                <Route key="search" path={`${match.path}/report`} exact component={Report} />
                                {/* 홈 */}
                                <Route path={`${match.path}`} component={MainMobile} />
                            </Switch>
                        </Suspense>
                    </RestoredScroll>
                </React.Fragment>
            );
        } else {
            if (loading) {
                return <div className="af_loading more" />;
            }
            if (error) {
                return <StationError error={error} />;
            }
            return (
                <React.Fragment>
					<HelmetTitle title = {title} description = {description} keyword = {keywords} canonicalPath = {canonicalPath} type = {MAIN_STATION} />
                    <div id="bs-container" ref={this.contentHeaderRef}>
                        <Leftpane stationInfo={stationInfo} />
                        <div id="bs-contents">
                            <ContentsHeader
                                match={match}
                                stationInfo={stationInfo}
                                onExtend={this.handleDidUpdate}
                                onDidUpdate={this.handleDidUpdate}
                            />
                            <article id="contents" style={contentStyle}>
                                <RestoredScroll>
                                    <Suspense fallback={<div className="af_loading more"></div>}>
                                        <Switch>
                                            <Route path={`${match.path}`} exact component={Home} />
                                            <Route
                                                path={`${match.path}/vods/:page_type`}
                                                render={(props) => {
                                                    const { page_type } = props.match.params;
                                                    // vod 메뉴들 정책상 통합이 되어 다음과 같이 진행
                                                    // 2024.11.21 하이라이트 종료, 하이라이트로 진입시 전체 vod 페이지로 리다이렉트
                                                    if (page_type === 'highlight') {
                                                        return <Redirect to={`${match.url}/vods`} />;
                                                    }

                                                    // 업로드 클립, 유저클립, 별풍선 클립은 클립으로 통합
                                                    if (['myclip', 'balloonclip'].includes(page_type)) {
                                                        return <Redirect to={{
                                                            pathname: `${match.url}/vods/clip`,
                                                        }} />
                                                    }

                                                    if (page_type === 'user') {
                                                        // 유저 vod와 업로드 vod 는 업로드 vod 로 통합
                                                        return <Redirect to={{
                                                            pathname: `${match.url}/vods/normal`,
                                                        }} />
                                                    }

                                                    return <Vods {...props} />;
                                                }}
                                                key={`posts_bbs_no${location.pathname}`}
                                            />
                                            <Route
                                                path={`${match.path}/vods`}
                                                component={Vods}
                                              />
                                            <Route path={`${match.path}/shopfreeca`} component={Shopfreeca} />
                                            <Route
                                                path={`${match.path}/posts/:bbs_no`}
                                                component={Posts}
                                                key={`posts_bbs_no${location.pathname}`}
                                            />
                                            <Route path={`${match.path}/posts`} component={Posts} key="posts" />
                                            <Route path={`${match.path}/guest`} component={Guest} />
                                            <Route path={`${match.path}/search`} component={Search} key={`search${location.search}`} />
                                            <Route
                                                key="edit-client"
                                                path={`${match.path}/post/:title_no(\\d+)/:method(edit)`}
                                                component={Write}
                                            />
                                            <Route path={`${match.path}/post/:title_no(\\d+)`} component={Post} />
                                            <Redirect
                                                push={false}
                                                from={`${match.path}/redirect/:method(write)/:bbs_no`}
                                                to={`${match.path}/post/:method(write)/:bbs_no`}
                                            />
                                            <Route path={`${match.path}/post/:method(write)/:bbs_no`} component={Write} />
                                            <Redirect
                                                push={false}
                                                from={`${match.path}/redirect/:method(write)`}
                                                to={`${match.path}/post/:method(write)`}
                                            />
                                            <Route path={`${match.path}/post/:method(write)`} component={Write} />
                                            <Route component={Home} />
                                        </Switch>
                                    </Suspense>
                                </RestoredScroll>
                            </article>
                        </div>
                        <Footer />
                    </div>
                    <div id="side-layer">
                        <FloatingBanner/>
                    </div>
                    
                    <NoticePopup isLocationChange={this.isLocationChange} />
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        stationInfo: state.stationReducer.stationInfo,
        pageReducer: state.pageReducer,
    };
};
// props 값으로 넣어 줄 상태를 정의해줍니다.
const mapDispatchToProps = (dispatch) => {
    return {
        wrapClassName: (classname) => {
            dispatch(wrapClassName(classname));
        },
        homeDispatch: (type) => {
            dispatch(homeDispatch(type));
        },
        fetchStationInfo: (id) => {
            return dispatch(fetchStationInfo(id));
        },
        fetcBjInfo: () => {
            dispatch(fetcBjInfo());
        },
        fetchNotice: () => {
            dispatch(fetchNotice());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Station));
